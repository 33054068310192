import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';
import { Input, Button, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AddInvestigator from '../../components/AddInvestigator';
import './searchbar.scss'
import Loader from '../../../common/components/Loader';
import OutsideClick from '../../../common/components/OutsideClick';
import {
  getCookie, isSponsor,
} from '../../../utils';
import { createSearchHistoryAction } from '../SearchHistory/logic';
import { getAutocompleteValuesAction, getAutocompleteValuesResetAction, getAutocompleteValuesCancelAction } from './logic';
import { getTemplate } from './template';
import SearchResults from '../SearchResults';
import { getDatasetListDataAction, getDatasetListResetAction } from '../SearchResults/logic';
import { addPiAction, addPiResetAction } from '../DocumentList/logic'

const { Search } = Input;
const emojiRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

const fieldPlaceholderMapping = {
  investigators: 'Investigator',
  centers: 'Center',
  projects: 'Project',
  networks: 'Network',
  clinical_trials: 'Clinical Trial',
  parent_centers: 'Parent Center',
}

const SearchBar = ({
  handleModal, searchPi, setsearchPi, fieldToSearch, setValueToCheck,
}) => {
  const [searchText, setSearchText] = useState('');
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [showTypeMore, setShowTypeMore] = useState(false);
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [showDocProfile, setShowDocProfile] = useState(false);
  const [addPiManually, setAddPiManually] = useState(false);
  const [params, setParams] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const autoCompleteData = useSelector((state) => state.autoCompleteData);
  const projectsData = useSelector((state) => state.projectsData);
  const addPiResponse = useSelector((store) => store.addPiResponse);

  const adminType = getCookie('permissions')

  const { flagName, analysisId } = handleModal
  useEffect(() => {
    setSearchText(parse(location.search).query || '');
  }, [parse(location.search).query]);

  useEffect(() => {
    if (autoCompleteData.flag) {
      setAutosuggestOptions(autoCompleteData.data);
    }
  }, [JSON.stringify(autoCompleteData)]);

  useEffect(() => {
    if (addPiResponse.flag && !addPiResponse.error) {
      dispatch(addPiResetAction())
      setsearchPi(false)
    }
    if (addPiResponse.error) {
      dispatch(addPiResetAction())
      setsearchPi(false)
    }
  }, [JSON.stringify(addPiResponse)]);

  const onSearch = (e) => {
    if (flagName === 'runAnalysis' || flagName === 'savedAnalysis') {
      setShowDocProfile(false)
      setDisplayDropdown(false);
      dispatch(getDatasetListResetAction())
      dispatch(createSearchHistoryAction({
        query: e,
      }))
      if (e && e.length > 2) {
        const paramss = {
          query: e,
          searchfield: fieldToSearch,
          currentTab: fieldToSearch === 'all' ? 'investigators' : fieldToSearch,
        };
        setParams(paramss);
      } else {
        const paramss = {
          query: '',
          searchfield: fieldToSearch,
          currentTab: fieldToSearch === 'all' ? 'investigators' : fieldToSearch,
        };
        setParams(paramss);
      }
      dispatch(
        getDatasetListDataAction({
          query: e,
          dataset: 'investigators',
        }),
      );
      setShowDocProfile(true)
    } else if (e && e.length > 2) {
      localStorage.setItem('all_advance_search', JSON.stringify({
        profile_fields: [{
          field: '', compare: '', value: '', operator: '',
        }],
        race_ethnicity: [{
          field: '', type: '', compare: '', value: '', operator: '',
        }],
        age_sex: [{
          field: '', compare: '', value: '', operator: '',
        }],
        employment_by_insurance: [{
          field: '', type: '', label: '', compare: '', value: '', operator: '',
        }],
        income_by_insurance: [{
          field: '', compare: '', value: '', operator: '',
        }],
        studies: [{
          field: '', compare: '', value: '', operator: '',
        }],
        cls_kol_score: [{
          field: '', compare: '', value: '', operator: '',
        }],
        sources: [],
        indications: [],
        alliance_type: [],
        total_number_of_pis: [{
          field: '', compare: '', value: '', operator: '',
        }],
        tier: [],
        census_available_countries: ['United States'],
        emerging_tag: [],
        cls_kol_score_class: [],
      }))

      const tab = fieldToSearch || parse(location.search).currentTab
      const alreadySearch = parse(location.search).searchfield || fieldToSearch
      const paramss = {
        query: e,
        searchfield: alreadySearch,
        currentTab: tab === 'all' ? 'investigators' : tab,
      };
      setDisplayDropdown(false);
      dispatch(createSearchHistoryAction({
        query: e,
      }))
      history.push(`/searchresults?${stringify(paramss)}`);
    } else {
      localStorage.setItem('all_advance_search', JSON.stringify({
        profile_fields: [{
          field: '', compare: '', value: '', operator: '',
        }],
        race_ethnicity: [{
          field: '', type: '', compare: '', value: '', operator: '',
        }],
        age_sex: [{
          field: '', compare: '', value: '', operator: '',
        }],
        employment_by_insurance: [{
          field: '', type: '', label: '', compare: '', value: '', operator: '',
        }],
        income_by_insurance: [{
          field: '', compare: '', value: '', operator: '',
        }],
        studies: [{
          field: '', compare: '', value: '', operator: '',
        }],
        sources: [],
        indications: [],
        alliance_type: [],
        total_number_of_pis: [{
          field: '', compare: '', value: '', operator: '',
        }],
        tier: [],
        cls_kol_score: [{
          field: '', compare: '', value: '', operator: '',
        }],
        census_available_countries: ['United States'],
        emerging_tag: [],
        cls_kol_score_class: [],
      }))

      const tab = fieldToSearch || parse(location.search).currentTab
      const alreadySearch = parse(location.search).searchfield || fieldToSearch
      const paramss = {
        query: '',
        searchfield: alreadySearch,
        currentTab: tab === 'all' ? 'investigators' : tab,
      };
      setDisplayDropdown(false);
      dispatch(createSearchHistoryAction({
        query: e,
      }))
      history.push(`/searchresults?${stringify(paramss)}`);
    }
  };

  const onChangeInput = (e) => {
    dispatch(getAutocompleteValuesCancelAction());
    if (e.target.value && e.target.value.trim().length <= 2) {
      setDisplayDropdown(true);
      setShowTypeMore(true);
      setSearchText(e.target.value);
      dispatch(getAutocompleteValuesResetAction());
    } else if (e.target.value && e.target.value.trim().length > 2 && !emojiRegex.test(e.target.value)) {
      setShowTypeMore(false);
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      if (fieldToSearch === 'all') {
        dispatch(
          getAutocompleteValuesAction({
            query: e.target.value.trim(),
          }),
        );
      } else if (fieldToSearch === 'parent_centers') {
        dispatch(
          getAutocompleteValuesAction({
            query: e.target.value.trim(),
            dataset: 'centers',
          }),
        );
      } else {
        dispatch(
          getAutocompleteValuesAction({
            query: e.target.value.trim(),
            dataset: fieldToSearch,
          }),
        );
      }
    } else {
      setDisplayDropdown(false);
      setSearchText('');
    }
  };

  const autoSuggestionItemClicked = (item) => {
    let obj = {};
    let win;
    if (item.type === 'Project') {
      let accessType = 'None';
      let shareType = '';
      let sharedProject = false;
      if (projectsData.flag && projectsData.data.data && projectsData.data.data.length) {
        const filteredList = projectsData.data.data.filter((prj) => (prj.project_id === item.project_id) && (prj.owner_user_id !== getCookie('userName')));
        if (filteredList.length) {
          accessType = filteredList[0].shared_details.access_type;
          shareType = filteredList[0].share_type;
          sharedProject = true;
        } else if (item.user_name !== `${getCookie('name')} ${getCookie('lastName')}`) {
          shareType = 'None';
        }
      }
      obj = {
        project_id: item.project_id,
        project_name: item.name,
        access_type: item.user_name === `${getCookie('name')} ${getCookie('lastName')}` ? undefined : accessType,
        share_type: item.user_name === `${getCookie('name')} ${getCookie('lastName')}` ? undefined : shareType,
        shared_project: item.user_name === `${getCookie('name')} ${getCookie('lastName')}` ? undefined : sharedProject,
      }
    } else if (item.type === 'Network') {
      obj = {
        query: searchText,
        id: item.network_id,
        type: item.type,
        currentTab: item.type,
      }
    } else if (item.type === 'PI') {
      obj = {
        query: searchText,
        id: item.id,
        type: item.type,
      }
    } else {
      obj = {
        query: searchText,
        id: item.id,
        type: item.type,
        currentTab: item.parent_flag === false ? 'centers' : 'parent_centers',
      }
    }
    setParams(obj);
    setDisplayDropdown(false);
    dispatch(createSearchHistoryAction({
      query: searchText,
    }))
    if (flagName === 'runAnalysis') {
      setShowDocProfile(true)
    } else if (flagName === 'savedAnalysis') {
      const finalData = {
        project_id: parse(location.search).project_id,
        site_id: item.affiliated_site_id,
        pi_id: item.id,
        is_cfl: adminType.includes('cfl_access') ? true : undefined,
        is_lcor: adminType.includes('lcor_access') ? true : undefined,
      }

      dispatch(addPiAction(finalData))
    } else if (item.type === 'Network') {
      win = window.open(`/search/networkdetails?${stringify(obj)}`, '_blank');
      win.focus();
    } else if (item.type === 'PI' && (flagName !== 'runAnalysis' || flagName !== 'savedAnalysis')) {
      win = window.open(`/search/investigatorprofile?${stringify(obj)}`, '_blank');
      win.focus();
    } else if (item.type === 'Project' && (flagName !== 'runAnalysis' || flagName !== 'savedAnalysis')) {
      win = window.open(`/projects/projectdetails?${stringify(obj)}`, '_blank');
      win.focus();
    } else {
      win = window.open(`/search/deepdives?${stringify(obj)}`, '_blank');
      win.focus();
    }
  };

  const onAddPiManually = () => {
    setAddPiManually(true)
  }

  const renderAutosuggestOptions = () => {
    if (autoCompleteData.flag && autosuggestOptions.length) {
      return autosuggestOptions.map((itm, index) => {
        return (
          <div
            className="search-dropdown-result-list-card"
            role="presentation"
            key={index}
            onClick={() => autoSuggestionItemClicked(itm)}
          >
            {getTemplate(itm.type, itm, index)}
          </div>
        );
      });
    }
    if (autoCompleteData.flag && autosuggestOptions.length === 0) {
      return (
        <div className="autosuggest-no-data-msg">
          {(flagName !== 'runAnalysis' && flagName !== 'savedAnalysis')
            ? (
              <span>
                No matches found, click &#39;Search&#39; to initiate plain text search.
              </span>
            )
            : (
              <div className="add-manual-pi-wrap">
                <div className="investigator-notfound-text">Investigator not found</div>
                <div className="card-person-img">
                  <UserOutlined />
                </div>
                <div className="btn-wrap"><Button className="add-pi-btn" type="primary" onClick={onAddPiManually}>Add Manually</Button></div>
                <div className="footer-text-investigator">Fill in the details and add  the investigator in the system</div>
              </div>
            )}
        </div>
      );
    }
    if (showTypeMore) {
      return (
        <div className="autosuggest-no-data-msg">
          Please type at least 3 characters to show autosuggested options or initiate search.
        </div>
      );
    }
    return null;
  };

  const handleCancel = () => {
    setsearchPi(false)
  };

  const checkDisableValue = () => {
    if (searchText.trim().length <= 2) {
      setValueToCheck(false)
    } else {
      setValueToCheck(true)
    }

    if (searchText.trim().length <= 2) {
      if (fieldToSearch === 'networks' || fieldToSearch === 'projects') {
        return false
      }
      return true
    }
    return false
  }

  const onSearchClick = () => {
    localStorage.removeItem('advance_search')
  }

  return (
    <div className="searchbar-section">
      { flagName === 'runAnalysis' || flagName === 'savedAnalysis' ? (
        <Modal
          title="Add Investigator"
          visible={searchPi}
          onCancel={handleCancel}
          className="modal-wrapper add-investigator-modal"
          maskClosable={false}
          destroyOnClose
          footer={null}
        >
          { !addPiManually ? (
            <Loader loading={addPiResponse.loading}>
              <div className="add-investigator-modal-search">
                <div className="add-investigator-modal-search-title">Search the investigator to be added to the Project</div>
                <div className="search-bar search-wrap">
                  <div className="search-bar-wrap">
                    <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
                      <Search
                        placeholder={(flagName === 'runAnalysis' || flagName === 'savedAnalysis') ? 'Search by investigator name' : 'Search for Investigator, Center, Parent Center, Project, Network and Clinical Trial'}
                        allowClear
                        value={searchText}
                        loading={autoCompleteData.loading}
                        enterButton={(flagName === 'runAnalysis' || flagName === 'savedAnalysis') ? (<Button disabled={checkDisableValue()}>Search investigator</Button>) : (<Button disabled={checkDisableValue()}>Search</Button>)}
                        size="large"
                        maxLength="256"
                        onSearch={onSearch}
                        onChange={onChangeInput}
                      />
                    </div>
                  </div>
                  <div className="search-dropdown-result-content">
                    {displayDropdown ? (
                      <OutsideClick
                        ignoreClickWithinClass="search-bar"
                      >
                        <div className="search-dropdown-result">
                          <div className="search-dropdown-result-list">
                            <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                              {renderAutosuggestOptions()}
                            </Loader>
                          </div>
                          {flagName && autosuggestOptions.length
                            ? (
                              <div className="add-manually-btn-wrap">
                                <div className="btn-wrap">
                                  <Button className="add-pi-btn" type="primary" onClick={onAddPiManually}>Add investigator manually</Button>
                                </div>
                              </div>
                            )
                            : null}
                        </div>
                      </OutsideClick>
                    ) : null}
                  </div>
                </div>
                <div className="inner-row-search-data">
                  {showDocProfile && <SearchResults addPiManually={addPiManually} modalProps={{ params, analysisId, setsearchPi }} />}
                </div>
              </div>
            </Loader>
          ) : (
            <div className="add-investigator-section">
              <AddInvestigator flag={flagName} analysisId={analysisId} setsearchPi={setsearchPi} setAddPiManually={setAddPiManually} />
            </div>
          ) }

        </Modal>
      ) : (
        <div className="search-bar search-wrap">
          <div className="search-bar-wrap">
            <div className={`search-box ${searchText.trim().length >= 3 ? 'activesearch' : ''}`}>
              <Search
                placeholder={(flagName === 'runAnalysis' || flagName === 'savedAnalysis') ? 'Search by investigator name' : fieldToSearch === 'all' ? 'Search for Investigator, Center, Project, Network and Clinical Trial' : `Search for ${(fieldPlaceholderMapping[fieldToSearch])}`}
                allowClear
                value={searchText}
                loading={autoCompleteData.loading}
                enterButton={(flagName === 'runAnalysis' || flagName === 'savedAnalysis') ? (<Button disabled={checkDisableValue() || isSponsor()}>Search investigator</Button>) : (<Button onClick={onSearchClick} disabled={checkDisableValue() || isSponsor()}>Search</Button>)}
                size="large"
                maxLength="256"
                onSearch={onSearch}
                onChange={onChangeInput}
                disabled={isSponsor()}
              />
            </div>
          </div>
          <div className="search-dropdown-result-content">
            {displayDropdown ? (
              <OutsideClick
                ignoreClickWithinClass="search-bar"
                onClickOutside={() => {
                  setDisplayDropdown(false);
                }}
              >
                <div className="search-dropdown-result">
                  <div className="search-dropdown-result-list">
                    <Loader loading={autoCompleteData.loading} error={autoCompleteData.error}>
                      {renderAutosuggestOptions()}
                    </Loader>
                  </div>
                </div>
              </OutsideClick>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

SearchBar.propTypes = {};

export default SearchBar;
